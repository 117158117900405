import { BasketItemPayload } from "./types";

// ToDo :: Fix me after R1. This function performs the following actions :
// Action 1: Add Bookin ItemID to additionalItems when not bookedIn item is issued to customer. This is only for collections with launchItemID=47026
export const manipulateMailsPayload = (items: BasketItemPayload[]): BasketItemPayload[] => {
  const royalMailIDs = ["6310", "6312", "47010", "47014"]; // RM itemIDs require Interpolation
  const parcelforceIDs = ["6316", "6317", "6318"]; // PF itemIDs require Interpolation

  return items.map((addItemWithBookin) => {
    // requiredBookin is temp transaction key passed in by journey complete step - only if true should we add additional basket items for collections with launchItemID=47026
    const { requiredBookin, ...addItem } = addItemWithBookin as BasketItemPayload & { requiredBookin?: string };

    if (!requiredBookin || addItem.itemID !== "47026") {
      return addItem;
    }

    let additionalBasketItems = addItem.additionalItems || [];
    // Action 1 starts here
    const itemsNeedingAttention = additionalBasketItems.filter((n) =>
      [...royalMailIDs, ...parcelforceIDs].includes(n.itemID)
    );

    try {
      if (itemsNeedingAttention.length > 0) {
        itemsNeedingAttention.forEach((checkItem) => {
          if (parcelforceIDs.find((id) => checkItem.itemID === id)) {
            additionalBasketItems = [...additionalBasketItems, { itemID: "6315", valueInPence: 0, quantity: 1 }];
          }
          if (royalMailIDs.find((id) => checkItem.itemID === id)) {
            additionalBasketItems = [...additionalBasketItems, { itemID: "6309", valueInPence: 0, quantity: 1 }];
          }
        });
      }
    } catch (e) {
      console.log(`Failed to transform mails additional item: ${e as string}`);
      throw e;
    }

    return {
      ...addItem,
      additionalItems: additionalBasketItems,
    };
  });
};
