import { FulfillmentClient } from "postoffice-product-journey-api-clients/dist/fulfillment-api";
import { v4 as uuidv4 } from "uuid";
import { IngenicoPedClient } from "postoffice-peripheral-management-service";
import { FulfilmentTypeEnum, TransactionsApiInterface } from "./openapi/transaction-api-v2";
import {
  Callbacks,
  FulfillmentItem,
  Fulfiller,
  FulfillmentProcessor,
  BasketItemPayload,
  FulfillerResponse,
} from "./types";
import { PEDFulfiller } from "./fulfillers/ped";
import RemoteFulfiller from "./fulfillers/remote";
import { manipulateMailsPayload } from "./mails";

export const CommitAndFulfillProcessor = (
  transactionsV2Client: TransactionsApiInterface,
  callbacks: Callbacks,
  fulfillmentClient?: FulfillmentClient,
  deviceCallbacks?: {
    ped: IngenicoPedClient;
  }
): FulfillmentProcessor => {
  const successfullCommits: BasketItemPayload[] = [];
  const failedCommits: BasketItemPayload[] = [];
  const itemsToFulfill: FulfillmentItem[] = [];

  const process = async (basketId: string, items: BasketItemPayload[]): Promise<boolean> => {
    if (items.length === 0) {
      return false;
    }

    const entryPayload = manipulateMailsPayload(items);

    /* eslint-disable no-restricted-syntax */
    for (const item of entryPayload) {
      try {
        // Add requestUDID if it doesn't exist
        if (item.tokens && !item.tokens.requestUDID) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
          item.tokens = { ...item.tokens, requestUDID: uuidv4() };
        }

        /* eslint-disable no-await-in-loop */
        const result = await transactionsV2Client.createBasketEntry(basketId, item);

        successfullCommits.push(item);
        callbacks.onCommitSuccess(item, result.data);

        if (result.data.fulfilmentRequired) {
          itemsToFulfill.push(<FulfillmentItem>{
            basketItem: item,
            entry: result.data,
          });
        }
      } catch (error) {
        failedCommits.push(item);
        callbacks.onCommitError(item, error as Error);
      }
    }

    /* eslint-disable no-restricted-syntax */
    for (const item of itemsToFulfill) {
      try {
        const fulfiller = getFulfiller(basketId, item, transactionsV2Client, fulfillmentClient);
        /* eslint-disable no-await-in-loop */
        const response = await fulfiller.fulfill(item);
        if (response && callbacks.onFulfillmentSuccess) {
          callbacks.onFulfillmentSuccess(item.basketItem, response);
        }
      } catch (errorResponse) {
        if (callbacks.onFulfillmentError) {
          callbacks.onFulfillmentError(item.basketItem, errorResponse as FulfillerResponse | Error);
        }
      }
    }

    return Promise.resolve(true);
  };

  const getFulfiller = (
    basketId: string,
    item: FulfillmentItem,
    transactionClient: TransactionsApiInterface,
    fulfillmentApiClient?: FulfillmentClient
  ): Fulfiller => {
    if (!item.entry.fulfilmentType) {
      throw new Error("Fulfillment type has not been set");
    }

    switch (item.entry.fulfilmentType) {
      case FulfilmentTypeEnum.Ped:
        return PEDFulfiller(transactionClient, basketId, deviceCallbacks?.ped);
      case FulfilmentTypeEnum.Remote:
        // TODO - replace stubbed code
        return RemoteFulfiller(basketId, fulfillmentApiClient);
      default:
        throw new Error(`Unknown fulfillment type: ${item.entry.fulfilmentType || "unknown"}`);
    }
  };

  return Object.freeze({ process });
};

export default CommitAndFulfillProcessor;
