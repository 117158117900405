import { FulfillmentResponse } from "postoffice-product-journey-api-clients/dist/fulfillment-api";
import { EntryCore, EntryResponse } from "./openapi/transaction-api-v2";

export interface FulfillmentItem {
  basketItem: BasketItemPayload;
  entry: EntryResponse;
}

export interface MessagingNotice {
  id: string;
  description: string;
}

export interface ReceiptTemplate {
  template: string;
  context: Record<string, unknown>;
}

export interface FulfillerResponse {
  result: Record<string, unknown>;
  notice?: MessagingNotice;
  receipts?: ReceiptTemplate[];
}

export type FulfillmentProcessor = Readonly<{
  process: (basketId: string, items: BasketItemPayload[]) => Promise<boolean>;
}>;

export type Callbacks = {
  onCommitSuccess: (item: BasketItemPayload, response: EntryResponse) => void;
  onCommitError: (item: BasketItemPayload, response: Error) => void;
  onFulfillmentSuccess?: (item: BasketItemPayload, response: FulfillerResponse | FulfillmentResponse) => void;
  onFulfillmentError?: (item: BasketItemPayload, response: FulfillerResponse | Error) => void;
};

export interface PedFulfillerT {
  fulfill(item: FulfillmentItem): Promise<FulfillerResponse>;
}

export interface RemoteFulfillerT {
  fulfill(item: FulfillmentItem): Promise<FulfillmentResponse>;
}

export type Fulfiller = PedFulfillerT | RemoteFulfillerT;

export enum CommitAndFulfillPointEnum {
  BeforePayment = "beforePayment",
  AfterPayment = "afterPayment",
}
export interface NonTransactionFields {
  uniqueID?: string;
  commitAndFulfillPoint?: CommitAndFulfillPointEnum;
  entryType?: string;
  voidable?: string;
  quantityFixed?: string;
}

export type BasketItemPayload = EntryCore & NonTransactionFields;
