import { axiosClient } from "../common/axiosWrapper";

export type AuthProvider = () => Promise<{ Authorization: string }>;
export type Params = () => Promise<{ [key: string]: string }>;

export interface DataRecords {
  id: string;
  value: string | DataRecords[];
}

export interface DataResponse {
  hash: string;
  data: DataRecords[];
}

export interface Client {
  hash(): Promise<string>;
  data(): Promise<DataResponse>;
}

const client = (root: string, authHeaders: AuthProvider): Client => {
  const hash = async (): Promise<string> => {
    return axiosClient.get<string>({
      url: `${root}/configuration/hash`,
      headers: await authHeaders(),
    });
  };

  const data = async (): Promise<DataResponse> => {
    return axiosClient.get<DataResponse>({
      url: `${root}/configuration/data`,
      headers: await authHeaders(),
    });
  };

  return Object.freeze({ hash, data });
};

export default client;
