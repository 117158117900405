import axios from "axios";

export type AuthProvider = () => Promise<{ Authorization: string }>;
export type Params = () => Promise<{ [key: string]: string }>;

export interface Client {
  commit(branchID: string, nodeID: number, items: any[]): Promise<CommitBasketResponse>;
}

export interface CommitBasketResponse {
  basketID: string;
}

const client = (root: string, authHeaders: AuthProvider): Client => {
  const commit = async (branchID: string, nodeID: number, items: any[]): Promise<CommitBasketResponse> => {
    const response = await axios.post(
      `${root}/basket/`,
      {
        nodeID,
        branchID,
        items,
      },
      {
        headers: await authHeaders(),
      }
    );
    return response.data as CommitBasketResponse;
  };

  return Object.freeze({ commit });
};

export const mockClient = (): Client => {
  const commit = (branchID: string, nodeID: number, items: any[]): Promise<CommitBasketResponse> =>
    Promise.resolve({
      basketID: `${branchID}-${nodeID}-1`,
    });

  return Object.freeze({ commit });
};

export default client;
