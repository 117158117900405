import { IngenicoPedClient, PedError, PedResult } from "postoffice-peripheral-management-service";
import { FulfilmentStateEnum, TransactionsApiInterface } from "../../openapi/transaction-api-v2";
import { PedFulfillerT, FulfillmentItem, FulfillerResponse } from "../../types";

export enum PEDFulfillerActions {
  BalanceEnquiry = "balance",
  CashDeposit = "deposit",
  CashWithdrawal = "withdrawal",
  PinChange = "pinChange",
  Debit = "debit",
}

export type ReceiptTemplates = {
  [key: string]: string;
};

export const ReceiptTemplates: ReceiptTemplates = {
  [PEDFulfillerActions.BalanceEnquiry]: "banking/balance-enquiry",
  [PEDFulfillerActions.CashDeposit]: "banking/cash-deposit",
  [PEDFulfillerActions.CashWithdrawal]: "banking/cash-withdrawal",
  [PEDFulfillerActions.PinChange]: "banking/pin-change",
};

// ped rejects if its not approved but we
// handle in the same way when processing it
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const brokenPromises = async (promise: Promise<any>) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return await promise;
  } catch (error) {
    return error;
  }
};

const respond = (approved: boolean, response: FulfillerResponse): Promise<FulfillerResponse> => {
  return approved ? Promise.resolve(response) : Promise.reject(response);
};

export const PEDFulfiller = (
  client: TransactionsApiInterface,
  basketID: string,
  pedClient?: IngenicoPedClient
): PedFulfillerT => {
  const fulfill = async (item: FulfillmentItem): Promise<FulfillerResponse> => {
    if (!pedClient) {
      throw new Error("Device trigger is not defined");
    }

    if (!item.basketItem?.entryID) {
      throw new Error("entryID is not present in the basket item payload");
    }

    // default fulfillment status
    let state: FulfilmentStateEnum = FulfilmentStateEnum.Indeterminate;
    let fulfilmentTokens = {};

    const fulfilmentAction = item?.basketItem?.tokens?.fulfilmentAction;

    const pedFulfillerActions = Object.values(PEDFulfillerActions);
    if (!fulfilmentAction || !pedFulfillerActions.includes(fulfilmentAction as PEDFulfillerActions)) {
      throw new Error(`Unknown fulfiller action: ${fulfilmentAction || "unknown"}`);
    }
    const entryId = item.basketItem?.entryID.toString();
    const action = fulfilmentAction as PEDFulfillerActions;
    let result: PedResult | PedError;

    const transactionId = item.basketItem?.tokens?.horizonTransactionID;

    if (!transactionId) {
      throw new Error("horizonTransactionID is a mandatory token for banking / payments");
    }

    const amount = item.basketItem.valueInPence;

    switch (action) {
      case PEDFulfillerActions.BalanceEnquiry:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
        result = await brokenPromises(pedClient.balanceEnquiry(transactionId));
        break;
      case PEDFulfillerActions.CashDeposit:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
        result = await brokenPromises(pedClient.deposit(amount, transactionId));
        break;
      case PEDFulfillerActions.CashWithdrawal:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
        result = await brokenPromises(pedClient.withdrawal(amount, transactionId));
        break;
      case PEDFulfillerActions.PinChange:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
        result = await brokenPromises(pedClient.changePin(transactionId));
        break;
      case PEDFulfillerActions.Debit:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
        result = await brokenPromises(pedClient.debit(amount, transactionId));
        break;
      default:
        throw new Error(`Unknown fulfiller action: ${fulfilmentAction || "unknown"}`);
    }

    if (!result.responseCode) {
      const error = result as PedError;
      throw new Error(error.message);
    }

    fulfilmentTokens = {
      pan: result.tokenisedPan?.trim(),
      responseCode: result.metadata?.responseCode,
      transactionResultCode: result.metadata?.resultCode,
      transactionType: result.metadata?.transactionType,
      horizonTransactionID: transactionId,
      cardImpounded: result.metadata?.cardImpounded ? "Y" : "N",
    };

    if (result.approved) {
      state = FulfilmentStateEnum.Success;
    } else {
      state = FulfilmentStateEnum.Failure;
    }

    const transactionApiFulfilmentResponse = await client.updateBasketEntryFulfilment(basketID, entryId, {
      fulfilmentState: state,
      fulfilmentTokens,
    });

    if (transactionApiFulfilmentResponse.status !== 200) {
      throw new Error(`Failed to update fulfillment tokens - basketId: ${basketID} / entryId: ${entryId}`);
    }

    const response: FulfillerResponse = {
      result,
    };

    if (result.prompt !== undefined) {
      response.notice = result.prompt;
    }

    const receiptDetails = ReceiptTemplates[fulfilmentAction];

    if (!receiptDetails) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return respond(result.approved as boolean, response);
    }

    response.receipts = [
      {
        template: receiptDetails,
        context: {
          customerTicket: result.customerTicket,
        },
      },
    ];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return respond(result.approved as boolean, response);
  };

  return Object.freeze({ fulfill });
};

export default PEDFulfiller;
