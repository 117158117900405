import { TokenMask, IssuerSchemes, Items } from "../openapi/tokeniser-api";
import { axiosClient } from "../common/axiosWrapper";
export type AuthProvider = () => Promise<{ Authorization: string }>;
export type Params = () => Promise<{ [key: string]: string }>;

export interface Client {
  tokenMask(tokenType: string): Promise<TokenMask[]>;
  issuerSchemes(): Promise<IssuerSchemes[]>;
  getItems(): Promise<Items[]>;
}

const client = (root: string, authHeaders: AuthProvider): Client => {
  const tokenMask = async (tokenType: string): Promise<TokenMask[]> => {
    return axiosClient.get<TokenMask[]>({
      url: `${root}/tokeniser/tokenmask?tokenType=${tokenType}`,
      headers: await authHeaders(),
    });
  };

  const issuerSchemes = async (): Promise<IssuerSchemes[]> => {
    return axiosClient.get<IssuerSchemes[]>({
      url: `${root}/tokeniser/issuerschemes`,
      headers: await authHeaders(),
    });
  };

  const getItems = async (): Promise<Items[]> => {
    return axiosClient.get<Items[]>({
      url: `${root}/tokeniser/items`,
      headers: await authHeaders(),
    });
  };

  return Object.freeze({ tokenMask, issuerSchemes, getItems });
};

export const mockClient = (data: { [key: string]: any }): Client => {
  const tokenMask = async (tokenType: string): Promise<TokenMask[]> => {
    return new Promise((resolve) => {
      resolve(data.tokens as TokenMask[]);
    });
  };
  const issuerSchemes = async (): Promise<IssuerSchemes[]> => {
    return new Promise((resolve) => {
      resolve(data.issuerSchemes as IssuerSchemes[]);
    });
  };
  const getItems = async (): Promise<Items[]> => {
    return new Promise((resolve) => {
      resolve(data.items as Items[]);
    });
  };

  return Object.freeze({ tokenMask, issuerSchemes, getItems });
};

export default client;
