import axios from "axios";

export type Params = { [key: string]: string | boolean };
export type AnyCountry = Record<string, any>;
export type AuthProvider = () => Promise<{ Authorization: string }>;
export interface ICountries {
  countryName: string;
  iso2Code: string;
  iso3Code: string;
  countryCode: string;
  kahalaSupported: boolean;
}

export interface Client {
  getCountry(params: Params): Promise<ICountries>;
}

const client = (root: string, authHeaders: AuthProvider): Client => {
  const getCountry = async (params: Params): Promise<ICountries> => {
    const response = await axios.get(`${root}/product/countries`, {
      headers: await authHeaders(),
      params,
    });
    return response.data as ICountries;
  };

  return Object.freeze({ getCountry });
};

export const mockClient = (data: { [key: string]: any }): Client => {
  const getCountry = async (params: Params): Promise<ICountries> => {
    return new Promise((resolve) => {
      resolve(data.country as ICountries);
    });
  };

  return Object.freeze({ getCountry });
};

export default client;
