import axios from "axios";
import { CacheItemOptions as AmplifyCacheInterface } from "@aws-amplify/cache/src/types/Cache";
import { Node } from "../openapi/journey-api";
import { CacheProvider } from "../interfaces";


export type AuthProvider = () => Promise<{ Authorization: string }>;
export type Params = () => Promise<{ [key: string]: string }>;
export type Config = (key: string) => string;

export interface Client {
  get(journeyVersion?: string, params?: Record<string, string>): Promise<Node>;
}

const JOURNEY_CACHE_KEY = "journey";

const client = (root: string, authHeaders: AuthProvider, config?: Config, cacheProvider?: CacheProvider): Client => {
  const cacheOptions: AmplifyCacheInterface = {};
  if (config !== undefined && config[JOURNEY_CACHE_KEY] !== null) {
    const date = Date.now();
    cacheOptions.expires = date + 1000 * 60 * Number(config("JOURNEY_CACHE_TTL"));
  }

  const getJourney = async (journeyVersion, params): Promise<Node> => {
    try {
      if (cacheProvider) {
        const journeyCache = await cacheProvider.getItem(JOURNEY_CACHE_KEY);
        if (journeyCache) {
          return journeyCache as Node;
        }
      }
      if (journeyVersion === undefined) {
        journeyVersion = "";
      }
      let queryString = `${journeyVersion}`;
      if (params) {
        queryString =
          `${queryString}?` +
          Object.keys(params)
            .map((lineItem) => {
              return `${lineItem}=${params[lineItem]}`;
            })
            .join("&");
      }
      const response = await axios.get(`${root}/user-journey/${queryString}`, { headers: await authHeaders() });
      cacheProvider && cacheProvider.setItem(JOURNEY_CACHE_KEY, response.data as Node, cacheOptions);
      return response.data as Node;
    } catch (err) {
      console.error("GET JOURNEY", err);
      throw err;
    }
  };

  return Object.freeze({ get: getJourney });
};

export const mockClient = (data: { [key: string]: any }): Client => {
  const getJourney = async (): Promise<Node> => {
    return new Promise((resolve) => {
      resolve(data.journey as Node);
    });
  };

  return Object.freeze({ get: getJourney });
};

export default client;
