import axios from "axios";

export type AuthProvider = () => Promise<{ Authorization: string }>;
export type Params = Record<string, any>;
export type AnyParcel = Params;

export interface LastDespatchResponse {
  afterLastDespatch: boolean;
}

// FIXME: type needs to come from openapi
export interface ParcelList {
  carrierName: string;
  parcelCount: number;
  parcels: ParcelsItem[];
}
// FIXME: type needs to come from openapi
export interface ParcelsItem {
  id?: string;
  carrierId: string;
  trackingId: string;
  isSelected?: boolean;
  afterLastDespatch: boolean;
  preventCustomerPickup: boolean;
  status: string;
  acceptanceDate: number;
  addressLine1?: string;
  country?: string;
  postcode?: string;
  serviceName: string;
}
export interface Client {
  getLastDespatch(
    branchId: string,
    counterId: string,
    clerkId: string,
    carrierId: string
  ): Promise<LastDespatchResponse>;
  getParcels(params: Params): Promise<ParcelList>;
  getItems(params?: Record<string, string>): Promise<ParcelList>;
  putParcels(params: ParcelsItem[]): Promise<AnyParcel>;
  updateLastDispatch(carrierId: string, isLastDispatch: boolean): Promise<LastDespatchResponse>;
}

const client = (
  root: string,
  branchId: string,
  counterId: string,
  clerkId: string,
  authHeaders: AuthProvider
): Client => {
  const getLastDespatch = async (
    branchId: string,
    counterId: string,
    clerkId: string,
    carrierId: string
  ): Promise<LastDespatchResponse> => {
    try {
      const response = await axios.get(
        `${root}/despatch/last-despatch?branchId=${branchId}&counterId=${counterId}&clerkId=${clerkId}&carrierId=${carrierId}`,
        { headers: await authHeaders() }
      );
      return response.data as LastDespatchResponse;
    } catch (err) {
      console.error("Get Last Despatch", err);
      throw err;
    }
  };

  const getParcels = async (params: Params): Promise<ParcelList> => {
    try {
      const response = await axios.get(`${root}/despatch/parcels`, {
        headers: await authHeaders(),
        params: {
          branchId,
          counterId,
          clerkId,
          ...params,
        },
      });
      return response.data as ParcelList;
    } catch (err) {
      console.error("GET PARCELS", err);
      throw err;
    }
  };
  const getItems = async (params): Promise<ParcelList> => {
    try {
      const response = await axios.get(`${root}/despatch/parcels`, {
        headers: await authHeaders(),
        params: {
          branchId,
          counterId,
          clerkId,
          ...params,
        },
      });
      return response.data as ParcelList;
    } catch (err) {
      throw err;
    }
  };

  const putParcels = async (params: ParcelsItem[]): Promise<AnyParcel> => {
    const bodyParams = {
      branchId,
      counterId,
      clerkId,
      parcels: params,
    };
    try {
      const response = await axios.put(`${root}/despatch/parcels`, bodyParams, { headers: await authHeaders() });
      return response.data as AnyParcel;
    } catch (err) {
      console.error("Update Parcels", err);
      throw err;
    }
  };

  const updateLastDispatch = async (carrierId: string, isLastDispatch: boolean): Promise<LastDespatchResponse> => {
    const bodyParams = {
      afterLastDispatch: isLastDispatch,
    };
    try {
      const response = await axios.put(
        `${root}/despatch/last-despatch?branchId=${branchId}&counterId=${counterId}&clerkId=${clerkId}&carrierId=${carrierId}`,
        bodyParams,
        { headers: await authHeaders() }
      );
      return response.data as LastDespatchResponse;
    } catch (err) {
      console.error("updateLastDispatch", err);
      throw err;
    }
  };

  return Object.freeze({ getParcels, putParcels, getItems, getLastDespatch, updateLastDispatch });
};

export const mockClient = (data: { [key: string]: any }): Client => {
  const getLastDespatch = async (
    branchId: string,
    counterId: string,
    clerkId: string,
    carrierId: string
  ): Promise<LastDespatchResponse> => {
    return new Promise((resolve) => {
      resolve(data.afterLastDespatch as LastDespatchResponse);
    });
  };

  const getParcels = async (params: Params): Promise<ParcelList> => {
    return new Promise((resolve) => {
      resolve(data.parcels);
    });
  };
  const getItems = async (params): Promise<ParcelList> => {
    return new Promise((resolve) => {
      resolve(data.parcels);
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  const putParcels = async (params: ParcelsItem[]): Promise<AnyParcel> => {
    return new Promise((resolve) => {
      resolve(data.dispatchParcels as AnyParcel);
    });
  };
  const getLastDispatch = async (carrierId: string): Promise<AnyParcel> => {
    return new Promise((resolve) => {
      resolve(data.parcels as AnyParcel);
    });
  };

  const updateLastDispatch = async (carrierId: string, isLastDispatch: boolean): Promise<LastDespatchResponse> => {
    return new Promise((resolve) => {
      resolve(data.parcels as LastDespatchResponse);
    });
  };
  return Object.freeze({ getLastDespatch, getParcels, getItems, putParcels, updateLastDispatch });
};

export default client;
