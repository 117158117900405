import axios from "axios";
import moment from "moment";
export type AuthProvider = () => Promise<{ Authorization: string }>;
export type AnyEstimated = Record<string, any>;

export interface Client {
  get(
    originPostalCode: string,
    packageAcceptanceDateTime: string,
    destinationCountryCode: string,
    destinationPostalCode: string
  ): Promise<AnyEstimated>;
}

const client = (root: string, authHeaders: AuthProvider): Client => {
  const getEstimatedDeliveryDate = async (
    originPostalCode: string,
    packageAcceptanceDateTime: string,
    destinationCountryCode: string,
    destinationPostalCode: string
  ): Promise<AnyEstimated> => {
    const packageDateTimeFormat = moment(packageAcceptanceDateTime, "DD/MM/YYYY").format("YYYY-MM-DDTHH:mm:ssZ"); // packageDateTime + "T11:11:11Z";
    const response = await axios.get(`${root}/kahala/v1/guaranteedate`, {
      headers: await authHeaders(),
      params: {
        origin_postal_code: originPostalCode,
        package_acceptance_date_and_time: packageDateTimeFormat,
        destination_country_code: destinationCountryCode,
        destination_postal_code: destinationPostalCode,
      },
      validateStatus: () => true,
    });
    return response as AnyEstimated;
  };

  return Object.freeze({ get: getEstimatedDeliveryDate });
};

export default client;
