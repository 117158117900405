/**
 * axiosWrapper.ts
 * This is axios wrapper file, it will have all of the methods that axios have.
 * Errors will be centralized from axios
 * Interceptros can be defined here in axios
 */

import axios, { AxiosRequestConfig } from "axios";

interface IRequestParams<T> {
  url: string;
  headers: AxiosRequestConfig["headers"];
  params?: T;
}

interface IHttpClient {
  get<T>(parameters: IRequestParams<T>): Promise<T>;
  post<T>(parameters: IRequestParams<T>): Promise<T>;
  put<T>(parameters: IRequestParams<T>): Promise<T>;
  delete<T>(parameters: IRequestParams<T>): Promise<T>;
}

const mapError = (err: any) => {
  return {
    statusCode: err?.response?.status,
    message: err?.message,
    name: err?.name,
  };
};

class axiosWrapperClient implements IHttpClient {
  get<T>(parameters: IRequestParams<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, headers } = parameters;
      const options: AxiosRequestConfig = { headers };
      axios
        .get(url, options)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((err: any) => {
          reject(mapError(err));
        });
    });
  }

  post<T>(parameters: IRequestParams<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, params, headers } = parameters;
      const options: AxiosRequestConfig = { headers };
      axios
        .post(url, params, options)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((err: any) => {
          reject(mapError(err));
        });
    });
  }

  put<T>(parameters: IRequestParams<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, params, headers } = parameters;
      const options: AxiosRequestConfig = { headers };
      axios
        .put(url, params, options)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((err: any) => {
          reject(mapError(err));
        });
    });
  }

  delete<T>(parameters: IRequestParams<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, headers } = parameters;
      const options: AxiosRequestConfig = { headers };
      axios
        .delete(url, options)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((err: any) => {
          reject(mapError(err));
        });
    });
  }
}

export const axiosClient = new axiosWrapperClient();
