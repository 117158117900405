import axios from "axios";

export type AuthProvider = () => Promise<{ Authorization: string }>;
export type Params = () => Promise<{ [key: string]: string }>;
export type AnyProduct = Record<string, any>;

export interface Client {
  get(params: Params): Promise<AnyProduct>;
  getProducts(params: Params): Promise<AnyProduct>;
}

const client = (root: string, authHeaders: AuthProvider): Client => {
  const getProduct = async (params: Params): Promise<AnyProduct> => {
    const response = await axios.get(`${root}/product`, {
      headers: await authHeaders(),
      params: await params(),
    });
    return response.data as AnyProduct;
  };

  const getProducts = async (params: Params): Promise<{ products: AnyProduct[] }> => {
    const response = await axios.get(`${root}/product/query`, {
      headers: await authHeaders(),
      params: await params(),
    });

    return { products: response.data };
  };

  return Object.freeze({ get: getProduct, getProducts });
};

export const mockClient = (data: { [key: string]: any }): Client => {
  const getProduct = async (params: Params): Promise<AnyProduct> => {
    return new Promise((resolve) => {
      resolve(data.product as AnyProduct);
    });
  };

  const getProducts = async (params: Params): Promise<{ products: AnyProduct[] }> => {
    return Promise.resolve({ products: data.products });
  };

  return Object.freeze({ get: getProduct, getProducts });
};

export default client;
