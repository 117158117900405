type AuthProvider = () => Promise<{
  Authorization: string;
}>;

export interface FulfillmentClient {
  checkFulfillmentStatus(basketId: string, fulfillmentId: string): Promise<FulfillmentResponse>;
}

export interface FulfillmentResponse {
  fulfilled: boolean;
  fulfillmentId: string;
  tokens?: Record<string, unknown>;
}

async function checkFulfillmentStatus(basketId: string, fulfillmentId: string): Promise<FulfillmentResponse> {
  return Promise.resolve({
    fulfilled: true,
    fulfillmentId,
  });
}

export const buildFulfillmentApiClient = (baseDomain: string, version: string, authProvider: AuthProvider) => {
  return {
    checkFulfillmentStatus,
  };
};
